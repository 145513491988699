import Axios from "axios";

const axios = Axios.create({
  // baseURL: "http://localhost:3000/api/v1",  // localhost
  baseURL: "https://apist.gptgoodnews.com/api/v1", // staging
  // baseURL: "https://gptgn-prodapi.gptgoodnews.com/api/v1", // prod
  headers: {
    "x-access-token": localStorage.getItem("token")
  }
});

export default axios;