/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../../axios/axios"

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function data() {
  const [loader, setLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const getPlans = async () => {
    setLoader(true);
    await axios
      .get("/admin/plans")
      .then((res) => {
        setLoader(false);
        setPlans(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  }
  useEffect(()=>{
    getPlans()
  },[])

  const handleUserDelete = (plan_id) => {
    axios
    .post("/admin/delete-plan" , {plan_id})
    .then((res) => {
      getPlans();
    })
    .catch((err) => {
      console.log("deleteUser err", err);
    });
  }


 const rowsData =  plans.map((item)=>{
  return (
    {
      plans: <Author image={team2} name={item.name} email="" />,
      price: <Job title={item.price} description="" />,
      description: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.features[0].description}
        </MDTypography>
      ),
      status: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.status}
        </MDTypography>
      ),
      action: (
          <MDBox>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" mr={2}>
              Edit
              </MDTypography>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => handleUserDelete(item._id)}>
              Delete
              </MDTypography>
          </MDBox>
      )
    }
  )
        
})

  return {
    columns: [
      { Header: "plans", accessor: "plans", width: "45%", align: "left" },
      { Header: "price", accessor: "price", align: "left" },
      { Header: "description", accessor: "description", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: rowsData
  };
}
