/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../axios/axios"

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function data() {
  const [user, setUser] = useState([])
  const [loader, setLoader] = useState(false);
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const getUsers = async () => {
    setLoader(true);
    await axios
      .get("/admin/users")
      .then((res) => {
        setLoader(false);
        setUser(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  }
  useEffect(()=>{
    getUsers()
  },[])

  const handleUserDelete = (user_id) => {
    axios
    .post("/admin/delete-user" , {user_id})
    .then((res) => {
      getUsers();
    })
    .catch((err) => {
      console.log("deleteUser err", err);
    });
  }

 const rowsData =  user.map((item)=>{
  return (
    {
      user: <Author image={team2} name={item.name} email="" />,
      phone: <Job title={item.phone} description="" />,
      email: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.email}
        </MDTypography>
      ),
      status: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.status}
        </MDTypography>
      ),
      action: (
          <MDBox>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" mr={2}>
              Edit
              </MDTypography>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => handleUserDelete(item._id)}>
              Delete
              </MDTypography>
          </MDBox>
      )
    }
  )
        
})

  return {
    columns: [
      { Header: "user", accessor: "user", width: "45%", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "phone", accessor: "phone", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: rowsData
  };
}
